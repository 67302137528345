<template>
  <loading v-if="isLoading" />
  <error v-else-if="isError" />
  <content-not-view v-else-if="!subPermission.store || !subPermission.update" />

  <div v-else>
    <b-row>
      <b-col xs="12" class="mb-1">
        <b-card class="card-request-informations">
          <b-card-text class="mt-2 mb-2">
            <b-row class="mb-1">
              <b-col lg="12">
                <span class="h4 text-primary">
                  <feather-icon icon="MinusIcon" size="25"> </feather-icon>
                  {{ $t("g.requestInformations") }} :</span
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6" class="mb-1">
                <span class="h5 text-secondary">
                  <feather-icon icon="GitCommitIcon"> </feather-icon>
                  {{ $t("g.clientName") }} :
                </span>
                <span class="h5 text-secondary mr-1 ml-1">
                  {{ requestData.client ? requestData.client.name : "--" }}
                </span>
              </b-col>

              <b-col xs="6" class="mb-1"
                ><span class="h5 text-secondary">
                  <feather-icon icon="GitCommitIcon"> </feather-icon>

                  {{ $t("g.serviceCode") }} :
                </span>
                <span class="h5 text-secondary mr-1 ml-1">
                  {{ requestData.service_code }}
                </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col xs="6" class="mb-1"
                ><span class="text-secondary h5">
                  <feather-icon icon="GitCommitIcon"> </feather-icon>

                  {{ $t("g.date") }} :
                </span>
                <span class="text-secondary h5 mr-1 ml-1">
                  {{ format(requestData.receive_date) || "--" }}
                </span>
              </b-col>

              <b-col xs="6" class="mb-1"
                ><span class="h5 text-secondary">
                  <feather-icon icon="GitCommitIcon"> </feather-icon>

                  {{ $t("g.serviceName") }} :
                </span>
                <span class="h5 text-secondary mr-1 ml-1">
                  {{ requestData.service.name }}
                </span>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="6" class="mb-1"
                ><span class="text-secondary h5">
                  <feather-icon icon="GitCommitIcon"> </feather-icon>

                  {{ $t("g.category") }} :
                </span>
                <span class="h5 text-secondary mr-1 ml-1">
                  {{ requestData.category.name }}
                </span>
              </b-col>

              <b-col xs="6" class="mb-1"
                ><span class="h5 text-secondary">
                  <feather-icon icon="GitCommitIcon"> </feather-icon>

                  {{ $t("g.review_status") }} :
                </span>
                <span class="h5 text-secondary mr-1 ml-1">{{
                  requestData.is_reviewed
                    ? $t("g.reviewedDone")
                    : $t("g.NotReviewedYet")
                }}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col xs="6"
                ><span class="h5 text-secondary">
                  <feather-icon icon="GitCommitIcon"> </feather-icon>

                  {{ $t("g.sampleType") }} :
                </span>
                <span class="h5 text-secondary mr-1 ml-1">
                  {{ requestData.sample_type.name }}
                </span>
              </b-col>

              <b-col xs="6"
                ><span class="h5 text-secondary">
                  <feather-icon icon="GitCommitIcon"> </feather-icon>

                  {{ $t("g.theEndDate") }} :
                </span>
                <span class="h5 text-secondary mr-1 ml-1">{{
                  format(requestData.excpected_review_end_date) || "--"
                }}</span>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="pb-2">
      <b-col xs="2" lg="3">
        <b-overlay
          :show="success_1"
          rounded="sm"
          @shown="onShown"
          @hidden="onHidden"
        >
          <b-card
            border-variant="success"
            bg-variant="transparent"
            class="shadow-none card-files"
          >
            <b-card-text>
              {{ $t("g.requestToIssueACertificate") }}
            </b-card-text>

            <a
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="relief-primary"
              class="d-block col-12 btn btn-relief-primary"
              :href="
                requestData.cert_request_doc
                  ? requestData.cert_request_doc.path
                  : ''
              "
              target="_blank"
            >
              <span class="mr-1 ml-1">{{ $t("g.view") }}</span>
            </a>

            <b-row class="mt-1">
              <b-col lg="6">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="relief-success"
                  class="d-block col-12"
                  ref="show"
                  @click="certificateFunc"
                >
                  <span>{{ $t("g.acception") }}</span>
                </b-button>
              </b-col>
              <b-col lg="6">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="relief-danger"
                  class="d-block col-12"
                  @click="
                    () => {
                      form_data.is_cert_request_accepted = 0;
                      certificate_status = !certificate_status;
                    }
                  "
                >
                  <span>{{ $t("g.rejection") }}</span>
                </b-button>
              </b-col>
            </b-row>
            <transition name="fade">
              <b-row v-if="certificate_status">
                <b-col>
                  <b-form ref="form" class="repeater-form mt-1">
                    <!-- Row Loop -->
                    <b-row ref="row">
                      <!-- Item Name -->
                      <b-col md="12">
                        <b-form-textarea
                          id="textarea-default"
                          placeholder="Textarea"
                          rows="3"
                          v-model="form_data.cert_request_reject_reason"
                        />
                      </b-col>
                    </b-row>
                  </b-form>
                </b-col>
              </b-row>
            </transition>
          </b-card>

          <template #overlay>
            <div class="text-center">
              <feather-icon icon="CheckIcon" size="24" />
              <b-card-text>{{
                $t("g.thisDocumentHasBeenApproved")
              }}</b-card-text>
              <b-button
                ref="cancel"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-danger"
                size="sm"
                aria-describedby="cancel-label"
                @click="
                  () => {
                    form_data.is_cert_request_accepted = 0;
                    success_1 = false;
                  }
                "
                v-if="!showPaymentReceipt"
              >
                {{ $t("g.cancel") }}
              </b-button>
            </div>
          </template>
        </b-overlay>
      </b-col>

      <b-col xs="2" lg="3">
        <b-overlay
          :show="success_2"
          rounded="sm"
          @shown="onShown"
          @hidden="onHidden"
        >
          <b-card
            border-variant="success"
            bg-variant="transparent"
            class="shadow-none card-files"
          >
            <b-card-text>{{ $t("g.shippingDocument") }} </b-card-text>

            <a
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="relief-primary"
              class="d-block col-12 btn btn-relief-primary"
              :href="
                requestData.shipping_doc ? requestData.shipping_doc.path : ''
              "
              target="_blank"
            >
              <span class="mr-1 ml-1">{{ $t("g.view") }}</span>
            </a>
            <b-row class="mt-1">
              <b-col lg="6">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="relief-success"
                  class="d-block col-12"
                  ref="show"
                  @click="shippingFunc"
                >
                  <span>{{ $t("g.acception") }}</span>
                </b-button>
              </b-col>
              <b-col lg="6">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="relief-danger"
                  class="d-block col-12"
                  @click="
                    {
                      (form_data.is_shipping_accepted = 0),
                        (shipping_status = !shipping_status);
                    }
                  "
                >
                  <span>{{ $t("g.rejection") }}</span>
                </b-button>
              </b-col>
            </b-row>
            <transition name="fade">
              <b-row v-if="shipping_status">
                <b-col>
                  <b-form ref="form" class="repeater-form mt-1">
                    <!-- Row Loop -->
                    <b-row ref="row">
                      <!-- Item Name -->
                      <b-col md="12">
                        <b-form-textarea
                          id="textarea-default"
                          placeholder="Textarea"
                          rows="3"
                          v-model="form_data.shipping_reject_reason"
                        />
                      </b-col>
                    </b-row>
                  </b-form>
                </b-col>
              </b-row>
            </transition>
          </b-card>

          <template #overlay>
            <div class="text-center">
              <feather-icon icon="CheckIcon" size="24" />
              <b-card-text
                >{{ $t("g.thisDocumentHasBeenApproved") }}
              </b-card-text>
              <b-button
                ref="cancel"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-danger"
                size="sm"
                aria-describedby="cancel-label"
                @click="
                  () => {
                    form_data.is_shipping_accepted = 0;
                    success_2 = false;
                  }
                "
                v-if="!showPaymentReceipt"
              >
                {{ $t("g.cancel") }}
              </b-button>
            </div>
          </template>
        </b-overlay>
      </b-col>

      <b-col xs="2" lg="3">
        <b-overlay
          :show="success_3"
          rounded="sm"
          @shown="onShown"
          @hidden="onHidden"
        >
          <b-card
            border-variant="success"
            bg-variant="transparent"
            class="shadow-none card-files"
          >
            <b-card-text> {{ $t("g.factoryDocument") }} </b-card-text>

            <a
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="relief-primary"
              class="d-block col-12 btn btn-relief-primary"
              :href="
                requestData.factory_doc ? requestData.factory_doc.path : ''
              "
              target="_blank"
            >
              <span class="mr-1 ml-1">{{ $t("g.view") }}</span>
            </a>
            <b-row class="mt-1">
              <b-col lg="6">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="relief-success"
                  class="d-block col-12"
                  ref="show"
                  @click="FactoryFunc"
                >
                  <span>{{ $t("g.acception") }}</span>
                </b-button>
              </b-col>
              <b-col lg="6">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="relief-danger"
                  class="d-block col-12"
                  @click="
                    {
                      (form_data.is_factory_accepted = 0),
                        (factory_status = !factory_status);
                    }
                  "
                >
                  <span>{{ $t("g.rejection") }}</span>
                </b-button>
              </b-col>
            </b-row>
            <transition name="fade">
              <b-row v-if="factory_status">
                <b-col>
                  <b-form ref="form" class="repeater-form mt-1">
                    <!-- Row Loop -->
                    <b-row ref="row">
                      <!-- Item Name -->
                      <b-col md="12">
                        <b-form-textarea
                          id="textarea-default"
                          placeholder="Textarea"
                          rows="3"
                          v-model="form_data.factory_reject_reason"
                        />
                      </b-col>
                    </b-row>
                  </b-form>
                </b-col>
              </b-row>
            </transition>
          </b-card>
          <template #overlay>
            <div class="text-center">
              <feather-icon icon="CheckIcon" size="24" />
              <b-card-text>
                {{ $t("g.thisDocumentHasBeenApproved") }}
              </b-card-text>
              <b-button
                ref="cancel"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-danger"
                size="sm"
                aria-describedby="cancel-label"
                @click="
                  () => {
                    form_data.is_factory_accepted = 0;
                    success_3 = false;
                  }
                "
                v-if="!showPaymentReceipt"
              >
                {{ $t("g.cancel") }}
              </b-button>
            </div>
          </template>
        </b-overlay>
      </b-col>

      <b-col xs="2" lg="3">
        <b-overlay
          :show="success_4"
          rounded="sm"
          @shown="onShown"
          @hidden="onHidden"
        >
          <b-card
            border-variant="success"
            bg-variant="transparent"
            class="shadow-none card-files"
          >
            <b-card-text>
              {{ $t("g.supplierDocument") }}
            </b-card-text>

            <a
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="relief-primary"
              class="d-block col-12 btn btn-relief-primary"
              :href="
                requestData.cert_request_doc
                  ? requestData.cert_request_doc.path
                  : ''
              "
              target="_blank"
            >
              <span class="mr-1 ml-1">{{ $t("g.view") }}</span>
            </a>
            <b-row class="mt-1">
              <b-col lg="6">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="relief-success"
                  class="d-block col-12"
                  ref="show"
                  @click="supplierFunc"
                >
                  <span>{{ $t("g.acception") }}</span>
                </b-button>
              </b-col>
              <b-col lg="6">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="relief-danger"
                  class="d-block col-12"
                  @click="
                    {
                      (form_data.is_supplying_accepted = 0),
                        (supplier_status = !supplier_status);
                    }
                  "
                >
                  <span>{{ $t("g.rejection") }}</span>
                </b-button>
              </b-col>
            </b-row>
            <transition name="fade">
              <b-row v-if="supplier_status">
                <b-col>
                  <b-form ref="form" class="repeater-form mt-1">
                    <!-- Row Loop -->
                    <b-row ref="row">
                      <!-- Item Name -->
                      <b-col md="12">
                        <b-form-textarea
                          id="textarea-default"
                          placeholder="Textarea"
                          rows="3"
                          v-model="form_data.supplying_reject_reason"
                        />
                      </b-col>
                    </b-row>
                  </b-form>
                </b-col>
              </b-row>
            </transition>
          </b-card>
          <template #overlay>
            <div class="text-center">
              <feather-icon icon="CheckIcon" size="24" />
              <b-card-text>{{
                $t("g.thisDocumentHasBeenApproved")
              }}</b-card-text>
              <b-button
                ref="cancel"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-danger"
                size="sm"
                aria-describedby="cancel-label"
                @click="
                  () => {
                    form_data.is_supplying_accepted = 0;
                    success_4 = false;
                  }
                "
                v-if="!showPaymentReceipt"
              >
                {{ $t("g.cancel") }}
              </b-button>
            </div>
          </template>
        </b-overlay>
      </b-col>
      <b-col xs="2" lg="3">
        <b-overlay
          :show="success_5"
          rounded="sm"
          @shown="onShown"
          @hidden="onHidden"
        >
          <b-card
            border-variant="success"
            bg-variant="transparent"
            class="shadow-none card-files"
          >
            <b-card-text>
              {{ $t("g.commercialRegisterDocument") }}
            </b-card-text>

            <a
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="relief-primary"
              class="d-block col-12 btn btn-relief-primary"
              :href="
                requestData.commercial_record_doc
                  ? requestData.commercial_record_doc.path
                  : ''
              "
              target="_blank"
            >
              <span class="mr-1 ml-1">{{ $t("g.view") }}</span>
            </a>
            <b-row class="mt-1">
              <b-col lg="6">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="relief-success"
                  class="d-block col-12"
                  ref="show"
                  @click="commercialFunc"
                >
                  <span>{{ $t("g.acception") }}</span>
                </b-button>
              </b-col>
              <b-col lg="6">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="d-block col-12"
                  variant="relief-danger"
                  @click="
                    {
                      (form_data.is_comercial_record_accepted = 0),
                        (commercial_status = !commercial_status);
                    }
                  "
                >
                  <span>{{ $t("g.rejection") }}</span>
                </b-button>
              </b-col>
            </b-row>
            <transition name="fade">
              <b-row v-if="commercial_status">
                <b-col>
                  <b-form ref="form" class="repeater-form mt-1">
                    <!-- Row Loop -->
                    <b-row ref="row">
                      <!-- Item Name -->
                      <b-col md="12">
                        <b-form-textarea
                          id="textarea-default"
                          placeholder="Textarea"
                          v-model="form_data.comercial_record_reject_reason"
                          rows="3"
                        />
                      </b-col>
                    </b-row>
                  </b-form>
                </b-col>
              </b-row>
            </transition>
          </b-card>
          <template #overlay>
            <div class="text-center">
              <feather-icon icon="CheckIcon" size="24" />
              <b-card-text
                >{{ $t("g.thisDocumentHasBeenApproved") }}
              </b-card-text>
              <b-button
                ref="cancel"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-danger"
                size="sm"
                aria-describedby="cancel-label"
                @click="
                  () => {
                    form_data.is_comercial_record_accepted = 0;
                    success_5 = false;
                  }
                "
                v-if="!showPaymentReceipt"
              >
                {{ $t("g.cancel") }}
              </b-button>
            </div>
          </template>
        </b-overlay>
      </b-col>
    </b-row>
    <validation-observer ref="InvoiceForm">
      <b-form>
        <b-row>
          <b-col md="6" xl="6" class="mb-1">
            <b-form-group :label="$t('g.sendInvoice')" label-for="sendInvoice">
              <validation-provider
                #default="{ errors }"
                name="Invoice"
                rules="required"
              >
                <b-form-file
                  :placeholder="$t('g.uploadYourFileHere')"
                  drop-placeholder="Drop file here..."
                  multiple
                  :disabled="showPaymentReceipt"
                  v-model="form_data.invoice"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6" xl="6" class="mb-1">
            <b-form-group
              :label="$t('g.approvalOfTheCertificateRequest')"
              label-for="sendInvoice"
            >
              <validation-provider
                #default="{ errors }"
                name="CertificateRequest"
                rules="required"
              >
                <b-form-file
                  :placeholder="$t('g.uploadYourFileHere')"
                  drop-placeholder="Drop file here..."
                  multiple
                  :disabled="showPaymentReceipt"
                  v-model="form_data.cert_request_acceptance"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row>
      <b-col md="6" xl="6" class="mb-1">
        <b-form-group :label="$t('g.attached files')" label-for="other">
          <b-form-file
            :placeholder="$t('g.uploadYourFileHere')"
            drop-placeholder="Drop file here..."
            multiple
            :disabled="showPaymentReceipt"
            v-model="form_data.attachments"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="relief-primary"
        @click="handleRequestStatus()"
      >
        <span>{{ $t("g.Send") }}</span>
      </b-button>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardTitle,
  BButton,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BCardText,
  BFormFile,
  BFormGroup,
  BOverlay,
  BFormTextarea,
  BForm,
} from "bootstrap-vue";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import Ripple from "vue-ripple-directive";
import formatDate from "@/composables/format-date/format-date";
import store from "@/store/index";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    Loading,
    Error,
    BButton,
    BMedia,
    BMediaAside,
    BMediaBody,
    ContentNotView,
    BCardText,
    BFormFile,
    BFormGroup,
    BOverlay,
    BFormTextarea,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      currentId: null,
      reviewId: null,
      isLoading: true,
      isError: null,
      isEdit: null,
      requestData: null,
      form_data: {
        is_cert_request_accepted: null,
        cert_request_reject_reason: null,
        is_shipping_accepted: null,
        shipping_reject_reason: null,
        is_comercial_record_accepted: null,
        comercial_record_reject_reason: null,
        is_supplying_accepted: null,
        supplying_reject_reason: null,
        is_factory_accepted: null,
        factory_reject_reason: null,
        invoice: null,
        cert_request_acceptance: null,
        attachments: null,
        is_review_accepted: null,
      },
      showPaymentReceipt: false,
      certificate_status: false,
      shipping_status: false,
      factory_status: false,
      supplier_status: false,
      commercial_status: false,
      payment_document_status: false,
      success_1: false,
      success_2: false,
      success_3: false,
      success_4: false,
      success_5: false,
      format: null,
      subPermission: {},
    };
  },
  beforeMount() {
    this.format = formatDate;
    this.currentId = this.$route.params.id;
    // Get data from backend by ID
    this.getServiceRequest();

    if (this.$route.name === "EditRequestReview") {
      this.isEdit = true;
      this.reviewId = this.$route.params.reviewId;
      this.getServiceRequestReview();
    }
  },
  methods: {
    certificateFunc() {
      this.success_1 = !this.success_1;
      this.certificate_status = false;
      this.form_data.is_cert_request_accepted = 1;
      this.form_data.cert_request_reject_reason = "";
    },
    shippingFunc() {
      this.success_2 = !this.success_2;
      this.shipping_status = false;
      this.form_data.is_shipping_accepted = 1;
      this.form_data.shipping_reject_reason = "";
    },
    FactoryFunc() {
      this.success_3 = !this.success_3;
      this.factory_status = false;
      this.form_data.is_factory_accepted = 1;
      this.form_data.factory_reject_reason = "";
    },
    supplierFunc() {
      this.success_4 = !this.success_4;
      this.supplier_status = false;
      this.form_data.is_supplying_accepted = 1;
      this.form_data.supplying_reject_reason = "";
    },
    commercialFunc() {
      this.success_5 = !this.success_5;
      this.commercial_status = false;
      this.form_data.is_comercial_record_accepted = 1;
      this.form_data.comercial_record_reject_reason = "";
    },

    getServiceRequest() {
      this.$http
        .get(`admin/serviceRequests/${this.currentId}`)
        .then((res) => {
          this.requestData = res.data.data;
          setTimeout(() => {
            this.isLoading = false;
            this.isError = false;
            store.dispatch("GET_PERMISSION", "reviews");
            this.subPermission = store.state.permissions.sub;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.isError = true;
        });
    },

    getServiceRequestReview() {
      this.$http
        .get(`admin/serviceRequests/${this.currentId}/reviews/${this.reviewId}`)
        .then((res) => {
          this.requestData = res.data.data;
          this.isLoading = false;
          this.isError = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.isError = true;
        });
    },

    handleRequestStatus() {
      if (
        this.form_data.is_cert_request_accepted === null ||
        this.form_data.is_shipping_accepted === null ||
        this.form_data.is_comercial_record_accepted === null ||
        this.form_data.is_supplying_accepted === null ||
        this.form_data.is_factory_accepted === null
      ) {
        this.makeToast(
          "warning",
          this.$t("g.send.invalidInputBody"),
          this.$t("g.send.invalidInput")
        );
      } else {
        if (this.form_data.attachments === null) {
          delete this.form_data.attachments;
        }

        if (
          this.success_1 &&
          this.success_2 &&
          this.success_3 &&
          this.success_4 &&
          this.success_5
        ) {
          this.form_data.is_review_accepted = 1;
          console.log(this.$refs.InvoiceForm);
          this.$refs.InvoiceForm.validate().then((success) => {
            if (success) {
              let formData = new FormData();
              for (const key in this.form_data) {
                if (
                  key === "invoice" ||
                  key === "cert_request_acceptance" ||
                  key === "attachments"
                ) {
                  formData.append(key, this.form_data[key][0]);
                } else {
                  formData.append(key, this.form_data[key]);
                }
              }

              this.sendReview(formData);
            }
          });
        } else {
          this.form_data.is_review_accepted = 0;

          console.log(this.form_data);

          let formData = new FormData();
          for (const key in this.form_data) {
            if (
              key === "invoice" ||
              key === "cert_request_acceptance" ||
              key === "attachments"
            ) {
              return 0;
            } else {
              formData.append(key, this.form_data[key]);
            }
          }

          this.sendReview(formData);
        }
      }
    },
    sendReview(formData) {
      this.$http
        .post(`admin/serviceRequests/${this.currentId}/reviews`, formData)
        .then((res) => {
          this.makeToast(
              "success",
              res.data.message,
              this.$t("g.send.successTitle")
            );
          setTimeout(() => {
            this.$router.push({
              name: "ViewServiceRequest",
              params: { id: this.currentId },
            });
          }, 500);
        })
        .catch((err) => {
          for (const key in err.response.data.errors) {
            this.makeToast(
              "danger",
              err.response.data.errors[key][0],
              this.$t("g.send.errorTitle")
            );
          }
          console.log(err);
        });
    },
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    onShown() {
      // Focus the cancel button when the overlay is showing
      this.$refs.cancel.focus;
    },
    onHidden() {
      // Focus the show button when the overlay is removed
      this.$refs.show.focus;
    },
  },
};
</script>
